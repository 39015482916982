/* eslint-disable */
import {React, useState} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {graphql} from 'gatsby'
import striptags from 'striptags'
import {FormattedMessage, injectIntl} from 'react-intl';

import IsDesktop from '../../common/IsDesktop'
import IsMobile from '../../common/IsMobile'
import {Flex, Box} from '../../common/Grid'

import Container from '../../common/Container'

import Image from '../../common/Image'

import Button from "../../common/Button";

import OriginalNew from '../../../assets/images/newpacks/original-en.png'
import OriginalNewFr from '../../../assets/images/newpacks/original-fr.png'
import VelourNew from '../../../assets/images/newpacks/velou-en.png'
import VelourNewFr from '../../../assets/images/newpacks/velour-fr.png'
import TTNew from '../../../assets/images/newpacks/tt-en.png'
import TTNewFr from '../../../assets/images/newpacks/tt-fr.png'
import sendEvent from "../../../helpers/sendAnalytics";

import RTTImage2024 from '../../../assets/images/rtt_2024_en.jpg'
import RTTImage2024Fr from '../../../assets/images/rtt_2024_fr.jpg'

import FacialCubes from '../../../assets/images/FacialCubes-Web-ProductPage-EN.png'
import FacialCubesFr from '../../../assets/images/FacialCubes-Web-ProductPage-FR.png'
import OriginalEn from '../../../assets/images/Bath_Web_Original_795x450px.jpg'
import OriginalFr from '../../../assets/images/Bath_Web_Original_795x450px.jpg'
import OriginalLogo from '../../../assets/images/royale_original_logo_image.webp'
import VelourEn from '../../../assets/images/Bath_Web_Velour_795x450px.jpg'
import VelourFr from '../../../assets/images/Bath_Web_Velour_795x450px.jpg'
import VelourLogo from '../../../assets/images/velour_logo.webp'
import PlushEn from '../../../assets/images/bt-plush2-en.webp'
import PlushFr from '../../../assets/images/bt-plush2-fr.jpg'
import PlushLogo from '../../../assets/images/up_logo_image.webp'
import ThreePlyEn from '../../../assets/images/bt-3ply2-en.webp'
import ThreePlyFr from '../../../assets/images/bt-3ply2-fr.jpg'
import ThreePlyLogo from '../../../assets/images/3ply-new-logo-tmp.webp'

import FacialNew from '../../../assets/images/Original-2024-EN.png'
import FacialNewFr from '../../../assets/images/Original-2024-FR.png'
import FacialNew2 from '../../../assets/images/Velour-2024-EN.png'
import FacialNewFr2 from '../../../assets/images/Velour-2024-FR.png'

import NapkinEn from '../../../assets/images/npk-packshot-en.webp'
import NapkinFr from '../../../assets/images/npk-packshot-fr.png'

import TigerTowelLogo from '../../../assets/images/royale_tigertowel_logo-tmp.webp'

const BlueContainer = styled.div`
  ${tw`bg-light-blue`}
`

const Description = styled.div`
  ${tw`mt-8 mb-8 font-raleway font-medium text-lg leading-normal text-blog-text`}
  max-width: 100%;
  width: 100%;

  p {
    margin: 0px;
    max-width: 100%;
    width: 100%;
    font-family: "Raleway", "Lucida Grande", Tahoma, sans-serif;
  }

  h2 {
    ${tw`font-light`}
  }

  a.coupon {
    ${tw`outline-none font-montserrat text-sm text-center no-underline cursor-pointer font-semibold text-white mt-8 -mb-10`}
    padding: 15px 30px;
    border: 1px solid #3475B7;
    background-color: #3475B7;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    line-height: 1.258;
    width: auto;
    color: '#fff';
    border-radius: 9999px;
    display: inline-block;

    &:hover,
    &:focus {
      background-color: #6db3f8;
      border-color: #6db3f8;
      color: #fff;
      ${tw`shadow-outline`}
    }
  }
`

const ProductHeader = styled.h2`
  ${tw`font-montserrat font-light text-4xl leading-tight text-black m-0 my-20`}
  max-width: 500px;

  p {
    margin: 0px;

    sup {
      font-size: 12pt;
      top: -15px;
    }
  }

  @media screen and (max-width: 64em) {
    ${tw`mb-6 text-2xl`}
    word-break: break-word;
  }
`

const BlueBottomContainer = styled.div`
  ${tw`bg-light-blue pt-10`}
`

const LogoImg = styled(Image)`
  @media screen and (min-width: 64em) {
    width: 240px;
    height: auto;
    margin-bottom: 25px;
  }

  @media screen and (max-width: 64em) {
    max-width: 200px;
    height: auto;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 45em) {
    max-width: 100%;
    width: 100%;
    margin-top: 0px;
  }
`

const LeftBox = styled(Box)``

const RightBox = styled(Box)`
  img {
    width: 100%;
  }

  &.rightBox-bbca9194-963a-4ad3-b701-80387cb652ba img,
  &.rightBox-53274db6-d39c-40c4-bed5-40b1c6a7af56 img {
    width: 350px;
    height: auto;
    margin: 0 calc(50% - 175px);
  }
`

const ProductCategoryBlockWrapper = styled.section`
  ${tw`bg-light-blue py-16`}
  display: block !important;
  @media screen and (max-width: 64em) {
    ${tw`py-8`}
  }

  .product-category-image, .mobile-image {
    display: none;
  }

  @media(max-width: 1023px){
    .mobile-image {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
    .pimage {
      display: none;
    }
  }

  .anchor-target {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }
`

const ProductCategoryBlock = (props) => {
  let {
    modification,
    title,
    video,
    product_header,
    logo_image,
    products,
    guid,
    intl
  } = props;

  let {
    description,
    product_category_image
  } = props;

  let reviewId = ''

  const locale = (intl.locale === 'fr') ? 'fr_CA' : 'en_CA';
  const env = (process.env.GATSBY_ACTIVE_ENV === 'production') ? 'production' : 'staging';
  let BVtitle = title
  let productType = '';
  let reviewUrl = (intl.locale === 'fr') ? '../../../fr/avis' : '../../../reviews'
  let gtagTracking = 'AW-951001886/OHJYCIWSmf0YEJ7GvMUD';

  // HACK UNTIL COUPONS ARE DONE BY ITD
  if (process.env.GATSBY_ACTIVE_ENV === 'production' || true) {

    let miq = '';
    let ga = '';

    switch (guid) {
      // RVUP
      case '8b06378d-e705-4376-9e19-99ff1990884b':
        productType = 'RVUP';
        if (intl.locale === 'en') {
          sendEvent({ga:{category:'Coupon_Click',action:'Click',label: 'RVUP_Coupon_Click'}})
        } else {
          sendEvent({ga:{category:'Coupon_Click',action:'Click',label: 'RVUP_Coupon_Click_FR'}})
        }

        break;
      // BATHROOM TISSUE
      case '7828b95f-2724-4e2c-b389-631e42955091':
        productType = 'BATHROOMTISSUE';
        miq = "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);";
        if (intl.locale === 'en') {
          sendEvent({ga:{category:'Coupon_Click',action:'Click',label: 'Velour_Coupon_Click'}})
        } else {
          sendEvent({ga:{category:'Coupon_Click',action:'Click',label: 'Velour_Coupon_Click_FR'}})
        }
        reviewId = 'OSR'
        break;
      // TIGER TOWEL
      case 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae':
        productType = 'TIGERTOWEL';
        gtagTracking = 'AW-951001886/VQbQCN2gm_0YEJ7GvMUD';
        miq = "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);";
        miq += "var a2img=document.createElement('img');a2img.style.width='1px';a2img.style.height='1px';a2img.src='https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:yotvgnb&fmt=3';document.body.appendChild(a2img);";
        if (intl.locale === 'en') {
          sendEvent({ga:{category:'Coupon_Click',action:'Click',label: 'Tiger_Towel_Coupon_Click'}})
        } else {
          sendEvent({ga:{category:'Coupon_Click',action:'Click',label: 'Tiger_Towel_Coupon_Click_FR'}})
        }
        reviewId = (intl.locale === 'fr') ? 'new_rtt_1r_123_master_fr' : 'new_rtt_1r_123_master_en'
        BVtitle = 'Tiger Towel'
        product_category_image = (intl.locale === 'fr') ? RTTImage2024Fr : RTTImage2024
        break;
      // FACIAL TISSUE
      case 'bbca9194-963a-4ad3-b701-80387cb652ba':
      case '53274db6-d39c-40c4-bed5-40b1c6a7af56':
      case '121a0dec-b670-47f7-801a-970a945fd1e1':
      case '2a5a3a6b-6206-42ab-b752-1da88e52dcad':
        productType = 'FACIALTISSUE';
        gtagTracking = 'AW-951001886/GG8WCO7klv0YEJ7GvMUD';
        miq = "var aimg=document.createElement('img');aimg.style.width='1px';aimg.style.height='1px';aimg.src='https://secure.adnxs.com/px?id=1169155&seg=19418271&t=2';document.body.appendChild(aimg);";
        if (intl.locale === 'en') {
          sendEvent({ga:{category:'Coupon_Click',action:'Click',label: 'Facial_Coupon_Click'}})
        } else {
          sendEvent({ga:{category:'Coupon_Click',action:'Click',label: 'Facial_Coupon_Click_FR'}})
        }
        reviewId = (intl.locale === 'fr') ? '2pft100_master_fr' : '2pft100_master_en'
        BVtitle = (intl.locale === 'fr') ? 'Mouchoirs Royale® Original à 2 épaisseurs' : 'Royale® Original 2-Ply Facial Tissue'
        break;
      default:
    }
    description = description.replace('class="coupon"', `class="coupon" onClick="fbq('track','Purchase');gtag('event','conversion',{'send_to':'AW-951001886/S9_KCIL27pIBEJ7GvMUD'});${miq}${ga}"`)
  }

  if (guid === '121a0dec-b670-47f7-801a-970a945fd1e1' || guid === '2a5a3a6b-6206-42ab-b752-1da88e52dcad') {
    product_category_image = (intl.locale === 'fr') ? FacialCubesFr : FacialCubes
  }

  if (guid === 'c0426664-78c9-42b8-be11-0282938a8db3') {
    product_category_image = (intl.locale === 'fr') ? OriginalFr : OriginalEn;
    logo_image =  OriginalLogo
  }

  if (guid === '7828b95f-2724-4e2c-b389-631e42955091') {
    product_category_image = (intl.locale === 'fr') ? VelourFr : VelourEn
    logo_image =  VelourLogo
  }

  if (guid === '8b06378d-e705-4376-9e19-99ff1990884b') {
    product_category_image = (intl.locale === 'fr') ? PlushFr : PlushEn
    logo_image = PlushLogo
  }

  if (guid === '09e9f205-4c9d-4fc6-9628-c193e498d628') {
    product_category_image = (intl.locale === 'fr') ? ThreePlyFr : ThreePlyEn
    logo_image = ThreePlyLogo
  }

  if (guid === 'c0355a4f-6856-47fb-9b90-5555a3859fad') {
    product_category_image = (intl.locale === 'fr') ? NapkinFr : NapkinEn
  }

  if (guid === 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae') {
    logo_image = TigerTowelLogo
  }


  if (guid === '53274db6-d39c-40c4-bed5-40b1c6a7af56') {
    product_category_image = (intl.locale === 'fr') ? FacialNewFr2 : FacialNew2
  }

  if (guid === 'bbca9194-963a-4ad3-b701-80387cb652ba') {
    product_category_image = (intl.locale === 'fr') ? FacialNewFr : FacialNew
  }

  if (guid === '8b06378d-e705-4376-9e19-99ff1990884b') {
    description = description.replace('class="coupon"', `class="ultraplus-coupon coupon"`);
  }

  // TEMP HACK UNTIL PRODUCTS DONE
  // SV
  // 3Ply
  if (guid === '53274db6-d39c-40c4-bed5-40b1c6a7af56') {
    reviewId = (intl.locale === 'fr') ? '3pft88_master_fr' : '3pft88_master_en'
    BVtitle = (intl.locale === 'fr') ? 'Mouchoirs à 3 épaisseurs Royale® VelourMC' : 'Royale® Velour™ 3-Ply Facial Tissue'
  }

  // Napkins
  if (guid === 'c0355a4f-6856-47fb-9b90-5555a3859fad') {
    gtagTracking = 'AW-951001886/oMIeCIXFmf0YEJ7GvMUD';
    reviewId = (intl.locale === 'fr') ? '1pnap_master_fr' : '1pnap_master_en'
    BVtitle = (intl.locale === 'fr') ? 'Serviettes de table ROYALE®' : 'ROYALE® Napkins'
  }

  let anchorId = '';
  if (guid === '8b06378d-e705-4376-9e19-99ff1990884b') {
    // velour-ultra
    anchorId = 'velour-ultra-section';
    reviewId = (intl.locale === 'fr') ? 'vupr_master_fr' : 'vupr_master_en'
    BVtitle = 'Ultra Plush'
  } else if (guid === '7828b95f-2724-4e2c-b389-631e42955091') {
    // velour
    anchorId = 'velour-section';
    reviewId = (intl.locale === 'fr') ? 'vdr_master_fr' : 'vdr_master_en'
    BVtitle = 'Velour'
  } else if (guid === 'c0426664-78c9-42b8-be11-0282938a8db3') {
    // original
    anchorId = 'original-section';
    reviewId = (intl.locale === 'fr') ? 'odr2_master_fr' : 'odr2_master_en'
    BVtitle = 'Original'
  } else if (guid === '09e9f205-4c9d-4fc6-9628-c193e498d628') {
    // 3-ply
    anchorId = 'threeply-section';
    reviewId = (intl.locale === 'fr') ? '3pog2_master_fr' : '3pog2_master_en'
    BVtitle = '3-Ply Strong'
  }
  if(productType === 'FACIALTISSUE') {
    if(guid != "121a0dec-b670-47f7-801a-970a945fd1e1" && guid != "2a5a3a6b-6206-42ab-b752-1da88e52dcad"){
      let text = (intl.locale === 'fr') ? 'Écrire un avis' : 'Write a review'
      let reviewCode = '<a style="text-decoration: none" href="' + reviewUrl + '?bvid=' + reviewId + '&title=' + BVtitle + '" target="_blank"><div id="rating-summary-' + guid + '" data-bv-show="inline_rating" data-bv-product-id="' + reviewId + '"></div><small style="margin-bottom: 15px; display: block" class="normal">' + text + '</small></a>'
      description = description.replace('</h2>\n<p>', '</h2>\n ' + reviewCode + ' </p>')
    }

  }

  const [showReviews, setShowReviews] = useState(false);

  return (
    <ProductCategoryBlockWrapper id={guid} data-guid={guid} name={anchorId} className={anchorId}
                                 modification={modification}>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */}
      <a id={anchorId} name={anchorId} aria-hidden="true" className="anchor-target" href="#"/>
      {
        product_category_image &&
        guid !== '8b06378d-e705-4376-9e19-99ff1990884b' ||
        guid !== 'bbca9194-963a-4ad3-b701-80387cb652ba' ||
        guid !== '53274db6-d39c-40c4-bed5-40b1c6a7af56' ||
        guid !== '2a5a3a6b-6206-42ab-b752-1da88e52dcad' ?
        <Image url={product_category_image} alt="Royale" className="product-category-image" sizes="100vw"/> : null}

      {
        guid === 'bbca9194-963a-4ad3-b701-80387cb652ba' ||
        guid === '53274db6-d39c-40c4-bed5-40b1c6a7af56' ||
        guid === '2a5a3a6b-6206-42ab-b752-1da88e52dcad' ||
        guid === '121a0dec-b670-47f7-801a-970a945fd1e1' ?
          <img src={product_category_image} className="mobile-image" alt={"Strong Royale"}/> : null
      }

      <Container modification={modification}>
        {logo_image ? <LogoImg url={logo_image} sizes="(min-width: 64em) 525px, 100vw" alt={striptags(title)}/> : null}
      </Container>

      <IsDesktop>
        <BlueContainer video={(video && guid !== 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae') ? 'yes' : 'no'}>
          <Container className="rvup-container" id={"rvup" + guid} width={1060}>
            {productType !== 'FACIALTISSUE' ?
              <a style={{'text-decoration': 'none'}} href={`${reviewUrl}?bvid=${reviewId}&title=${BVtitle}`}
                 target="_blank">
                <div
                  id={'rating-summary-' + guid}
                  data-bv-show="inline_rating"
                  data-bv-product-id={reviewId}
                />
                <small className="normal">{(intl.locale === 'fr') ? 'Écrire un avis' : 'Write a review'}</small>
              </a> : null
            }
            <Flex
              alignItems={guid == "bbca9194-963a-4ad3-b701-80387cb652ba" || guid == "53274db6-d39c-40c4-bed5-40b1c6a7af56" ? "center" : "flex-start"}>
              <LeftBox className={`leftb-${guid}`} width={1 / 2} pr={6}>
                <Description dangerouslySetInnerHTML={{__html: description}}/>
                <Button to="ee65cb8e-5cac-4013-bc5c-31d075d0ab89"
                        onClick={() => sendEvent({gtag: {send_to: gtagTracking}})} title="Buy Online"
                        style={{marginRight: 'auto'}}>
                  <FormattedMessage id='buy_online'/>
                </Button>
              </LeftBox>

              {
                guid === '8b06378d-e705-4376-9e19-99ff1990884b' ||
                guid === 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ||
                guid === '121a0dec-b670-47f7-801a-970a945fd1e1' ||
                guid === '53274db6-d39c-40c4-bed5-40b1c6a7af56' ||
                guid === 'bbca9194-963a-4ad3-b701-80387cb652ba' ||
                guid === 'c0426664-78c9-42b8-be11-0282938a8db3' ||
                guid === 'c0355a4f-6856-47fb-9b90-5555a3859fad' ||
                guid === '2a5a3a6b-6206-42ab-b752-1da88e52dcad' ?
                  (
                    <RightBox width={1 / 2}>
                      <img src={product_category_image} alt={"Strong Royale"}/>
                    </RightBox>
                  )
                  :
                  (
                    <RightBox className={'rightBox-' + guid} width={1 / 2}>
                      {product_category_image ? <Image url={product_category_image}
                                                       sizes={guid === '8b06378d-e705-4376-9e19-99ff1990884b' ? '(min-width: 64em) 50vw, 100vw' : '(min-width: 64em) 530px, 100vw'}/> : null}
                    </RightBox>
                  )
              }
            </Flex>
            {guid == 'c0426664-78c9-42b8-be11-0282938a8db3' ?
              <div className="product-low">
                <img src={intl.locale == 'fr' ? OriginalNewFr : OriginalNew} alt="Original Pack"/>
                <FormattedMessage id='paper.product1'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage>
              </div> : null

            }
            {guid == '7828b95f-2724-4e2c-b389-631e42955091' ?
              <div className="product-low">
                <img src={intl.locale == 'fr' ? VelourNewFr : VelourNew} alt="Velour Pack"/>
                <FormattedMessage id='paper.product2'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage>
              </div> : null

            }
            {guid == 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ?
              <div className="product-low">
                <img src={intl.locale == 'fr' ? TTNewFr : TTNew} alt="Tiger Towel Pack"/>
                <FormattedMessage id='paper.product3'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage>
              </div> : null
            }
          </Container>
        </BlueContainer>
      </IsDesktop>

      <IsMobile>
        <BlueContainer className={`bluemobile-${guid}`} video={(video) ? 'yes' : 'no'}>
          <Container width={1060} id={"rvup" + guid}>
            {productType !== 'FACIALTISSUE' ?
              <a style={{'text-decoration': 'none'}} href={`${reviewUrl}?bvid=${reviewId}&title=${BVtitle}`}
                 target="_blank">
                <div
                  id={'rating-summary-' + guid}
                  data-bv-show="inline_rating"
                  data-bv-product-id={reviewId}
                />
                <small className="normal">{(intl.locale === 'fr') ? 'Écrire un avis' : 'Write a review'}</small>
              </a> : null
            }
            <Flex>
              <Box>
                <Description dangerouslySetInnerHTML={{__html: description}}/>
                <Button to="ee65cb8e-5cac-4013-bc5c-31d075d0ab89"
                        onClick={() => sendEvent({gtag: {send_to: gtagTracking}})} title="Buy Online"
                        style={{marginRight: 'auto'}}>
                  <FormattedMessage id='buy_online'/>
                </Button>
                {guid === '8b06378d-e705-4376-9e19-99ff1990884b' || guid === 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ?
                  (
                    <div className="pimage">
                      {guid === '8b06378d-e705-4376-9e19-99ff1990884b' ? intl.locale === 'fr' ?
                        <img src={product_category_image} alt={"Strong Royale"}/> :
                        <img src={product_category_image} alt={"Strong Royale"}/> : null}
                      {guid === 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ? intl.locale === 'fr' ?
                        <img src={product_category_image} alt={"Strong Royale"}/> :
                        <img src={product_category_image} alt={"Strong Royale"}/> : null}
                    </div>
                  )
                  :
                  (
                    <div className="pimage">
                      {
                        product_category_image ?
                          <Image url={product_category_image}
                                                       sizes={guid === '8b06378d-e705-4376-9e19-99ff1990884b' ? '(min-width: 64em) 50vw, 100vw' : '(min-width: 64em) 530px, 100vw'}/> : null}
                    </div>
                  )
                }
              </Box>
            </Flex>
            {guid == 'c0426664-78c9-42b8-be11-0282938a8db3' ?
              <div className="product-low">
                <img src={intl.locale == 'fr' ? OriginalNewFr : OriginalNew} alt="Original Pack"/>
                <FormattedMessage id='paper.product1'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage>
              </div> : null

            }
            {guid == '7828b95f-2724-4e2c-b389-631e42955091' ?
              <div className="product-low">
                <img src={intl.locale == 'fr' ? VelourNewFr : VelourNew} alt="Velour Pack"/>
                <FormattedMessage id='paper.product2'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage>
              </div> : null

            }
            {guid == 'e49f6f51-1de2-45cf-872a-32b4ef99b7ae' ?
              <div className="product-low">
                <img src={intl.locale == 'fr' ? TTNewFr : TTNew} alt="Tiger Towel Pack"/>
                <FormattedMessage id='paper.product3'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html: txt}}/>
                  )}
                </FormattedMessage>
              </div> : null
            }
          </Container>
        </BlueContainer>
      </IsMobile>
    </ProductCategoryBlockWrapper>
  )
}

ProductCategoryBlock.propTypes = {
  guid: PropTypes.string.isRequired,
  modification: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  video: PropTypes.string,
  product_header: PropTypes.string.isRequired,
  logo_image: PropTypes.oneOfType([
    PropTypes.objectOf({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  product_category_image: PropTypes.oneOfType([
    PropTypes.objectOf({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  products: PropTypes.arrayOf([
    PropTypes.objectOf({
      title: PropTypes.string,
      images: PropTypes.string,
      guid: PropTypes.string
    })
  ]).isRequired
}

ProductCategoryBlock.defaultProps = {
  modification: '',
  video: null
}

export const productCategoryBlockFragment = graphql`
  fragment productCategoryBlockFragment on ProductCategoryBlock {
    guid
    id
    modification
    description
    video
    product_header
    logo_image_url
    product_category_image_url
    products {
      id
      title
      title_html
      guid
      images_url
    }
  }
`

export default injectIntl(ProductCategoryBlock)
